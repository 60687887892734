import { Box, Heading } from "theme-ui"
import React from "react"
import BackgroundImage from "gatsby-background-image"
import { Slide, Fade } from "react-awesome-reveal"

interface BannerProps {
  mobileImage: any
  desktopImage: any
  alt: string
  heading: string
  body?: React.ReactNode
}

const Banner = (props: BannerProps) => {
  const { mobileImage, desktopImage, alt, heading, body } = props
  const sources = [
    mobileImage,
    {
      ...desktopImage,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <BackgroundImage Tag="section" fluid={sources}>
      <Box
        sx={{
          height: ["820px", "640px"],
          position: "relative",
        }}
      >
        <Box
          sx={{
            maxWidth: "550px",
            position: "absolute",
            top: "50%",
            transform: ["none", "translateY(-50%)"],
          }}
          p={4}
        >
          <Slide triggerOnce={true} duration={400}>
            <Heading
              sx={{ fontSize: [4, 6], textTransform: "capitalize" }}
              mb={3}
              color="#202124"
            >
              {heading}
            </Heading>
          </Slide>
          {Boolean(body) && (
            <Fade triggerOnce={true} delay={400} duration={500}>
              <Box sx={{ fontSize: 1 }} color="#707070">
                {body}
              </Box>
            </Fade>
          )}
        </Box>
      </Box>
    </BackgroundImage>
  )
}

export default Banner
